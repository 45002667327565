<template>
    <div>
        <div class="sidebarClose mb-3">
            <span class="closeModal" @click="toggleSidebar">
                <span class="icon-close"></span>
            </span>
        </div>
        <div class="form-group mb-4">
            <h3>Find your roperties</h3>
            <input type="text" v-model="filterData.keywords" class="univ-input" placeholder="What are you looking for?">
        </div>
        <div class="form-group mb-4">
            <h3>Location</h3>
            <multiselect
                placeholder="Select Location"
                v-model="location"
                :options="locations"
                :custom-label="locationLabel"
                @select="getSelectedLocation"
                @remove="removedSelectedLocation"
            >
            </multiselect>
        </div>
        
        <div class="form-group mb-4">
            <h3>Property Type</h3>
            <!-- <multiselect
                placeholder="Select Type"
                v-model="category"
                :options="categories"
                :custom-label="categoryLabel"
                @select="getSelectedCategory"
                @remove="removedSelectedCategory"
            >
            </multiselect> -->
            <ul>
                <li v-for="(item,i) in categories" :key="i">
                    <input class="styled-checkbox" :id="'feature'+i" type="radio" v-model="filterData.category" :value="item.slug" @change="filters">
                    <label :for="'feature'+i">{{ item.title }}</label>
                </li>
            </ul>
        </div> 
        <div class="form-group mb-4">
            <h3>Property For</h3>
            <ul>
                <li>
                    <input class="styled-checkbox" type="radio" id="sale" value="sale" v-model="filterData.purpose" @change="filters" /><label for="sale"> Buy </label>
                </li>
                <li>
                    <input class="styled-checkbox" type="radio" id="rent" value="rent" v-model="filterData.purpose" @change="filters" /><label for="rent"> Rent</label>
                </li>
            </ul>
        </div>
        <template v-if="filterData.category !='' && filterData.category != 'land' ">
        <div class="form-group mb-4">
            <h3> Kitchen </h3>
            <div class="row">
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.kitchenFrom" class="univ-input" placeholder="From">
                </div>
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.kitchenTo" class="univ-input" placeholder="To">
                </div>
            </div>
        </div> 
        <div class="form-group mb-4">
            <h3> Floor </h3>
            <div class="row">
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.floorFrom" class="univ-input" placeholder="From">
                </div>
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.floorTo" class="univ-input" placeholder="To">
                </div>
            </div>
        </div> 
        <div class="form-group mb-4">
            <h3> Living Room </h3>
            <div class="row">
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.livingroomFrom" class="univ-input" placeholder="From">
                </div>
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.livingroomTo" class="univ-input" placeholder="To">
                </div>
            </div>
        </div> 
        <div class="form-group mb-4">
            <h3> Bathroom </h3>
            <div class="row">
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.bathroomFrom" class="univ-input" placeholder="From">
                </div>
                <div class="col-xl-6">
                    <input type="number" min="0" v-model="filterData.bathroomTo" class="univ-input" placeholder="To">
                </div>
            </div>
        </div> 
        <div class="form-group mb-3">                                
            <select class="univ-input" v-model="filterData.furnishing" @change="filters">
                <option value="">Furnishing</option>
                <option value="None">None</option>
                <option value="Full">Full</option>
                <option value="Semi">Semi</option>
            </select>
        </div>
        </template>

        <div class="form-group text-center reset-btn">
            <button class="univ-btn w-100" @click="filters">Search</button>
            <a href="javascript:void(0);" @click="resetFileter">Reset Search</a>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
export default {
    name: "PropertyFilter",
    components: { 
        Multiselect, 
    },
    data() {
        return {
            location: "",
            category: "",
            filterData:{
                keywords:this.$route.query.keywords,
                location:this.$route.query.location,
                purpose:this.$route.query.purpose,
                category:this.$route.query.category,

                kitchenFrom:this.$route.query.kitchenFrom,
                kitchenTo:this.$route.query.kitchenTo,

                floorFrom:this.$route.query.floorFrom,
                floorTo:this.$route.query.floorTo,

                bathroomFrom:this.$route.query.bathroomFrom,
                bathroomTo:this.$route.query.bathroomTo,

                livingroomFrom:this.$route.query.livingroomFrom,
                livingroomTo:this.$route.query.floorTo,

                furnishing:"",
            },
            categories: [],
            locations: [],
        };        
    },
    mounted() {
        let app = this;
        app.renderData();
    },
    methods: {
        async renderData(){
            let app = this;
            await axios.get(api.getUrl('/general-info')).then(function (response) {
                let responseData  = response.data.data;
                app.categories = responseData.property_category;
                let locationRows = [];
                responseData.location.forEach(function (item) {
                    locationRows.push({name:item.name,slug:item.slug});
                });
                app.locations = locationRows;
            });
        },
        filters(){
            let app = this;
            this.$emit("onSearch", app.filterData);
            let filterUrl = Helper.objToUrlParams(app.filterData);
            app.$router.push({path:`/search?${filterUrl}`}); 
        },
        toggleSidebar() {
            let app = this;
            app.$emit('toggle-event', false);
        },
        resetFileter(){
            let app = this;
            app.clearFilter();
            app.$emit("onSearch", app.filterData);
            app.$router.push({path:'/properties'}); 
        },
        clearFilter(){
            let app = this;
            app.filterData.purpose = "";
            app.filterData.keywords = "";
            app.filterData.category = "";
            app.filterData.location = "";
        },
        locationLabel(option) {
            return `${option.name}`;
        },
        getSelectedLocation(option){
            let app = this;
            app.filterData.location = option.slug;
            app.filters();
        },
        removedSelectedLocation(){
            let app = this;
            app.filterData.location = "";
            app.filters();
        },
        categoryLabel(option) {
            return `${option.title}`;
        },
        getSelectedCategory(option){
            let app = this;
            app.filterData.category = option.slug;
        },
        removedSelectedCategory(){
            let app = this;
            app.filterData.category = "";
        },
    },
    watch: {
        $route() {
            this.toggleSidebar();
        }
    }
}
</script>