<template>
    <div class="content-part property-list">
        <div :class="{ 'showSidebar': isSidebarVisible, 'filterSidebar': true }">
            <PropertyFilter @onSearch="filter($event)" @toggle-event="toggleSidebar($event)" />
        </div>
        <div class="container">
            <div class="row">
                <div class="col-xl-6">
                    <div class="actionLeft">
                        <ul>
                            <li>
                                <span class="moreFilter" @click="toggleSidebar">
                                    <span class="icon-filter"></span> More Filter
                                </span>
                            </li>
                            <li>Showing {{ from }} – {{ to }} of {{ total }} results</li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="shorting-list">
                        <span>Short By:</span>
                        <select v-model="filterData.sort" @change="onChange($event)" >
                            <option value="null">Default</option>
                            <option value="latest">Newest</option>
                            <option value="priceasc">Price low to high</option>
                            <option value="pricedesc">Price high to low</option>
                        </select>
                    </div>
                </div>
            </div>
            <template  v-if="dataStatus">
            <div class="row">
                <div class="col-xl-3 col-lg-3 col-md-6 col-6 mt-4" v-for="(item,i) in properties.data" :key="i">
                    <ItemList :item="item" />
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center mt-4">
                    <Pagination :rows.sync="properties" :filters="filterData" @meta-action="handeleMetaEvent($event)"/>
                </div>
            </div>
            </template>
            <template v-else> <NotFound /> </template>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Pagination from '../../../components/Pagination.vue'
import 'vue2-datepicker/index.css'
import ItemList from '@/components/ItemList'
import PropertyFilter from '@/components/PropertyFilter'
import Helper from "@/services/helper"
import NotFound from '@/components/NotFound'
export default {
    name: "PropertyList",
    components: { 
        Pagination, 
        ItemList,
        PropertyFilter,
        NotFound
    },
    data() {
        return {
            isSidebarVisible: false,
            properties: { 
                data: [], 
                links: {}, 
                meta: {}
            },
            from:"",
            to:"",
            total:"",
            filterData:{
                purpose:this.$route.query.purpose,
                keywords:this.$route.query.keywords,
                category:this.$route.query.category,
                location:this.$route.query.location,
                type:this.$route.query.type,
                sort:this.$route.query.sort?this.$route.query.sort:'latest',
            },
            dataStatus: true,
        };        
    },
    mounted() {
        let app = this;
        app.renderProperty(app.filterData);
    },
    methods: {
        async renderProperty(filters =[],page=""){
            Helper.showSpinner();
            let app = this;
            let url;
            if (filters) {
                let filterUrl = Helper.objToUrlParams(filters);
                url = `/properties?${filterUrl}${page}`;
            } else {
                url = `/properties`;
            }
            await axios.get(api.getUrl(url)).then(function (response) {
                let responseData        = response.data.data;
                if(response.data.success){
                    app.dataStatus = true;
                    app.properties.data     = responseData.data;
                    app.properties.meta     = responseData.meta;
                    app.properties.links    = responseData.links;
                    
                    app.from    =   responseData.meta.from;
                    app.to      =   responseData.meta.to;
                    app.total   =   responseData.meta.total;
                    app.$emit('totla-properties' , responseData.meta.total);
                }else{
                    app.from    =   0;
                    app.to      =   0;
                    app.total   =   0;
                    app.dataStatus = false;
                    app.properties.data     = [];
                    app.$emit('totla-properties' , 0);
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        filter($event) {
            this.renderProperty($event);
        },
        toggleSidebar($event) {
            this.isSidebarVisible = $event;
        },
        handeleMetaEvent($event){
            let app = this;
            app.from    =   $event.from;
            app.to      =   $event.to;
            app.total   =   $event.total;
        },
        onChange(event){
            let app = this;
            app.filterData.sort = event.target.value;
            app.renderProperty(app.filterData);
        }
    },
    created() {
        this.$watch(() => this.$route.query,(newQuery) => {
            let app = this;
            app.filterData.purpose  = newQuery.purpose;
            app.filterData.keywords = newQuery.keywords;
            app.filterData.category = newQuery.category;
            app.filterData.location = newQuery.location;
            app.filterData.type     = newQuery.type;
            app.filterData.sort     = newQuery.sort;
            
            //app.renderProperty(app.filterData);
        })
    },
}
</script>